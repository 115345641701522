<template>
    <div class="habit_wpr">
        <div class="title">
            {{title}}
            <div class="right">
                <div class="dropdown" @click="dropInfo = !dropInfo">
                    <i class="fas fa-info-circle"></i>
                    <div class="drp_wrapper" v-if="dropInfo" :class="{active : dropInfo}">
                        <div class="quick_info">
                            <div class="info_title">Requirement</div>
                            <p>In order to be compliant, you need to intentionally move your body for 15 minutes every day during this challenge.</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="dropdown" @click="dropOption = !dropOption">
                    <i class="fas fa-tachometer-alt"></i>
                    <div class="drp_wrapper" v-if="dropOption" :class="{active : dropOption}">
                        <ul>
                            <li v-for="items in options" :key="items.label">{{items.label}}: <span>{{items.value}}</span></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="habit_info">
            <ul>
                <li>
                    <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.5);">50<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="50"/>
                        </svg>
                    </div>
                    <h6>Habit Score</h6>                    
                </li>
                <li>
                    <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.5);">2<span>wk</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="50"/>
                        </svg>
                    </div>
                    <h6>Best Streak</h6>
                </li>
            </ul>            
        </div>
        <div class="habit_footer">
            <ul class="day_sorting">
                <li v-for="(item, index) in days" :key="index" :class="!item.status ? 'disabled' : ''" @click="dayDetails(item)">
                    <i :class="`far fa-${item.checkday ? 'check-square' : 'square'}`"></i>{{item.label}}
                </li>
            </ul>
        </div>
        <div class="modal secondary history_details" v-if="details">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1 class="m-0">{{dayName}}</h1>
                    <button class="close_btn" @click="details = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body">
                    <div class="activity_wpr">
                        <div class="activity_details mb-2">
                            <h4>Pellentesque venenatis ac libero ut venenatis? <i class="far fa-edit ml-auto" @click="edit = !edit"></i></h4>
                            <div class="details">
                                <div class="days_activity">
                                    <ul class="days">
                                        <li title="Monday">M</li>
                                        <li class="active" title="Tuesday">T</li>
                                        <li title="Wednesday">W</li>
                                        <li class="active" title="Thursday">T</li>
                                        <li title="Friday">F</li>
                                        <li class="active" title="Saturday">S</li>
                                        <li title="Sunday">S</li>
                                    </ul>
                                    <h5>8%</h5>
                                </div>
                                <span>Requirements:</span>
                                <p>In order to be compliant, you need to intentionally move your body for 15 minutes every day during this challenge.</p>
                                <ul>
                                    <li class="setting_wpr">
                                        <span>Performed:</span>
                                        <div class="perform_info" v-if="edit">
                                            <div class="form_grp p-0">
                                                <div class="group_item">
                                                    <Multiselect v-model="performance.value" v-bind="performance"></Multiselect>
                                                </div>
                                            </div>
                                            <textarea name="" id="" cols="30" rows="10" placeholder="Integer et ipsum tortor. Nullam venenatis metus eget turpis pellentesque vulputate."></textarea>
                                        </div>
                                        <div v-else class="perform_info">
                                            <label>{{perform ? 'Yes' : 'No'}}</label>
                                            <p>Integer et ipsum tortor. Nullam venenatis metus eget turpis pellentesque vulputate.</p>
                                        </div>
                                    </li>
                                    <li v-if="perform">
                                        <span>Proof:</span>
                                        <image-library v-if="edit"/>
                                        <img v-else src="@/assets/images/free-image2.jpg" alt="" class="mb-2">
                                    </li>
                                </ul>
                            </div>
                            <div class="action_footer" v-if="edit">
                                <button type="button" class="btn cancel_btn" @click="attr.customData.edit = false">Cancel</button>
                                <button type="button" class="btn save_btn">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import ImageLibrary from '@/components/image-library/ImageLibrary';
export default {
    name: 'HabitCards',
    props: ['title', 'options',],
    components:{
        Multiselect,
        ImageLibrary
    },
    data(){
        return{
            dropOption: false,
            dropInfo: false,
            details: false,
            dayName: '',
            perform: '',
            edit: false,
            performance: {
                mode: 'single',
                value: ['Yes'],
                options: [
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                ],
                createTag: true
            },
            days:[
                {
                    label: 'Monday',
                    status: true,
                    checkday: true,
                },
                {
                    label: 'Tuesday',
                    status: true,
                    checkday: true,
                },
                {
                    label: 'Wednesday',
                    status: true,
                    checkday: false,
                },
                {
                    label: 'Thursday',
                    status: true,
                    checkday: true,
                },
                {
                    label: 'Friday',
                    status: false,
                    checkday: false,
                },
                {
                    label: 'Saturday',
                    status: true,
                    checkday: false,
                },
                {
                    label: 'Sunday',
                    status: false,
                    checkday: false,
                },
            ]
        }
    },
    methods:{
        dayDetails(el){
            this.details = true;
            this.dayName = el.label;
            this.perform = el.status;
        }
    }
}
</script>
